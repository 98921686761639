// TicketDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../../components/Sidebar/Sidebar';

const TicketDetails = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { validationCode } = useParams();
  // ... (resto del código)
const [ticketDetails, setTicketDetails] = useState<{
    purchase_date: string;
    total_amount: string;
    customer: {
      name: string;
      surname: string;
      email: string;
      identification_number: string;
    };
    ticket_details: {
      ticket_type: {
        type: string;
        description: string;
      };
      quantity: number;
      current_amount: string;
    }[];
    validation_info: {
      validation_status: boolean;
      validation_date: string;
    } | null;
  } | null>(null);
  // ... (resto del código)
  

  useEffect(() => {
    const fetchTicketDetails = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tickets/verification/${validationCode}`);
        setTicketDetails(response.data);
      } catch (error) {
        console.error('Error fetching ticket details:', error);
      }
    };

    fetchTicketDetails();
  }, [apiUrl, validationCode]);

  

  // Renderiza los detalles del ticket aquí
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
        <Sidebar />
        <div style={{ flex: 1, padding: '20px' }}>
          <h1>Detalle de la compra</h1>
          {ticketDetails ? (
        <div>
          <p>Fecha de Compra: {ticketDetails.purchase_date}</p>
          <p>Total: $ {ticketDetails.total_amount}</p>
          <p>Cliente: {ticketDetails.customer.name} {ticketDetails.customer.surname}</p>
          
          <h3>Detalles de la Entrada:</h3>
          <ul>
            {ticketDetails.ticket_details.map((detail, index) => (
              <li key={index} style={{marginBottom: 10}}>
                <p>Tipo de Entrada: {detail.ticket_type.type}</p>
                <p>Cantidad: {detail.quantity}</p>
                <p>Importe: $ {detail.current_amount}</p>
              </li>
            ))}
          </ul>

          <h3>Información de Validación:</h3>
        {ticketDetails.validation_info ? (
          <div>
            <p>Estado de Validación: {ticketDetails.validation_info.validation_status ? 'Validado' : 'No Validado'}</p>
            <p>Fecha de Validación: {ticketDetails.validation_info.validation_date}</p>
          </div>
        ) : (
          <p>No hay información de validación disponible.</p>
        )}
        </div>
      ) : (
        <p>Cargando detalles del ticket...</p>
      )}
        </div>
      </div>
  );
};

export default TicketDetails;
