export interface links {
	label: string;
	link: string;
}

/*
export const navigationLinks: links[] = [
	{ label: 'Inicio', link: '/' },
	{ label: 'Nosotros', link: '/us' },
	{ label: 'Precios', link: '/prices' },
	{ label: 'Entradas', link: '/tickets' },
];

*/

export const navigationLinks: links[] = [
	{ label: 'Inicio', link: '/' },
	{ label: 'Nosotros', link: '/us' },
	{ label: 'Precios', link: '/prices' },
	/*{ label: 'Entradas', link: '/tickets' },*/
];
