import { createBrowserRouter } from 'react-router-dom';
import Home from '../Page/Home/Home';
import Prices from '../Page/Prices/Prices';
import Tickets from '../Page/Tickets/Tickets';
import Us from '../Page/Us/Us';
import Success from '../Page/Success/Success';
import Login from '../Page/Login/Login';
import Dashboard from '../Page/Dashboard/Dashboard';
import TransactionTable from '../Page/Dashboard/Tickets/TransactionTable';
import Sales from '../Page/Dashboard/Sales';
import PricesForm from '../Page/Dashboard/PricesForm';
import TicketDetails from '../Page/Dashboard/Tickets/TicketDetails';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/prices',
		element: <Prices />,
	},
	{
		path: '/us',
		element: <Us />,
	},
	/*{
		path: '/tickets',
		element: <Tickets />,
	},
	{
		path: '/success',
		element: <Success />,
	},*/
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/dashboard',
		element: <Dashboard />,
	},
	{
		path: '/dashboard/tickets',
		element: <TransactionTable />,
	},
	{
		path: '/dashboard/sales',
		element: <Sales />,
	},
	{
		path: '/dashboard/prices',
		element: <PricesForm />,
	},
	{
		path: '/dashboard/ticket-details/:validationCode',
		element: <TicketDetails />,
	}

]);
