export interface ticketInfoInterface {
	id: number;
	title: string;
	text: string;
}

export const ticketInfo: ticketInfoInterface[] = [
	{
		id: 1,
		title: 'NIÑOS',
		text: 'Descuentos especiales para niños entre 3 a 11 años',
	},
	{
		id: 2,
		title: 'JUBILADOS',
		text: 'Descuentos especiales para jubilados!',
	},
	{
		id: 3,
		title: 'RESIDENTES',
		text: 'Descuentos especiales para residentes del partido de la costa * SOLO POR BOLETERIA',
	},
];
